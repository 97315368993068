<template>
  <b-card title="">
    <b-tabs>
      <b-tab
        active
        @click="$router.push('/add-user')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add User</span>
        </template>

        <AddUser />
      </b-tab>
      <b-tab @click="$router.push('/all-user-tab')">
        <template #title>
          <feather-icon icon="FolderIcon" data-testid="all-users" />
          <span>All Users</span>
        </template>
        <router-view />

        <!-- <ViewUsers></ViewUsers> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>

import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import AddUser from './AddUser.vue'


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,

    AddUser,

  },
  data() {
    return {

    }
  },
}
</script>
